<template>
  <div class="report-main">
    <div class="nav-list">
      <div
        :class="navActive === index ? 'nav-con' : 'nav-con nav-con-active'"
        v-for="(item, index) in navList"
        :key="index"
        @click="navClick(index)"
      >
        <span>{{ item.name }}</span>
      </div>
    </div>
    <div class="tit-search flex space-between">
      <div class="mb-2">
        <el-button class="btn-primary" @click="goto('')">新增</el-button>
        <el-button
          class="btn-primary"
          @click="handleDownLoad"
          style="margin-right: 10px"
          >模板下载</el-button
        >
        <el-upload
          :multiple="false"
          :file-list="fileList"
          :show-file-list="false"
          class="editor-slide-upload"
          action="#"
          :http-request="customImport"
          style="display: inline-block"
        >
          <el-button class="btn-primary">数据导入</el-button>
        </el-upload>
      </div>
      <c-search
        v-model="pageParams"
        :searchItems="searchItems"
        @search="handleSearch"
        @reset="handleReset"
      ></c-search>
    </div>
    <div class="div-scroll table-wrapper">
      <c-table
        :index="false"
        :data="pageData"
        :tableColumns="tableColumns"
        :stripe="true"
        :pagination="pageParams"
        @change="pageGet"
        v-loading="pageLoading"
      >
        <template v-slot:custom="{ row }">
          <span
            class="opt-detail"
            style="margin-right: 10px"
            @click="gotoDetail(row)"
            >详情</span
          >
          <span class="opt-detail" style="margin-right: 10px" @click="goto(row)"
            >编辑</span
          >
          <span
            class="opt-detail"
            style="margin-right: 10px"
            @click="handleExport(row)"
            >导出</span
          >
          <span
            class="opt-detail"
            style="margin-right: 10px"
            @click="handlePageDelete(row)"
            >删除</span
          >
        </template>
      </c-table>
    </div>
    <el-dialog
      :close-on-click-modal="false"
      :title="title"
      :visible.sync="dialogVisible"
      :destroy-on-close="true"
    >
      <div class="div-scroll" style="max-height: 500px; padding: 0 5%">
        <div v-loading="handleLoading">
          <el-form
            ref="form"
            :model="handleData"
            label-width="80px"
            :rules="formRules"
          >
            <el-form-item label="记录人" prop="recordName">
              <el-input
                v-model="handleData.recordName"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
            <el-form-item label="记录时间" prop="recordTime">
              <el-date-picker
                style="width: 100%"
                v-model="handleData.recordTime"
                type="datetime"
                placeholder="选择日期时间"
                value-format="yyyy-MM-dd HH:mm:ss"
              >
              </el-date-picker>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="resetFields">取 消</el-button>
        <el-button class="btn-primary" @click="submit('form')">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import exportHttp from "@/config/exportHttp.js";
import MHandle from "@/mixins/MHandle.js";
import MPage from "@/mixins/MPage.js";
import Configs from "./config/index";
let mPage = MPage({
  pagePath: "",
  pageDelPath: "/inspection/record/delete",
  pageDelsPath: "",
  pageParams: {
    recordName: "",
    recordDate: "",
    type: "",
  },
  pageExportPath: "",
  pageExportParams: {},
});
let mHandle = MHandle({
  handleGetPath: "",
  handleInsertPath: "/inspection/record/save",
  handleUpdatePath: "/inspection/record/update",
  handleDownLoadPath: "",
  handleData: {},
});
export default {
  mixins: [mPage, mHandle],
  data() {
    let { searchItems, tableColumns, formItems, formRules } = Configs(this);
    return {
      searchItems,
      tableColumns,
      formItems,
      formRules,
      navList: [
        { value: 0, name: "日常巡检记录" },
        { value: 1, name: "经常巡检记录" },
      ],
      navActive: 0,
      fileList: [],
      dialogVisible: false,
      title: "",
      defaultHandleData: {
        recordName: "",
        recordTime: "",
      },
      templateUrl:
        "https://bridge-itest1.obs.cn-east-3.myhuaweicloud.com/routingInspection/template/import.xlsx",
    };
  },
  mounted() {
    this.navClick(this.navActive);
  },
  methods: {
    navClick(i) {
      this.navActive = i;
      this.pageParams.type = Number(i) + 1;
      this.pagePath = "/inspection/record/listPage";
      this.pageGet(true);
    },
    pageGetAfterFn(data) {
      data.list = data.records;
      return data;
    },
    handleSearch() {
      this.pageGet(true);
    },
    handleReset() {
      this.idList = [];
      this.pageParams = {
        recordName: "",
        recordDate: "",
        type: Number(this.navActive) + 1,
      };
      this.pageGet(true);
    },
    handleExport(data) {
      exportHttp
        .post("/inspection/record/exportWord/" + data.id, null, {
          responseType: "blob",
        })
        .then((res) => {
          let blob = new Blob([res.data], {
            type: `application/msword`, //word文档为msword,pdf文档为pdf
          });
          const link = document.createElement("a");
          let url = URL.createObjectURL(blob);
          link.href = url;
          link.download = decodeURIComponent(
            res.headers["content-disposition"].split("=")[1]
          );
          link.click();
          URL.revokeObjectURL(url);
          link.remove();
        })
        .catch((err) => console.warn(err));
    },
    // 模板下载
    handleDownLoad() {
      //window.location.href = this.templateUrl;
      exportHttp
        .post("/inspection/record/exportExcelTemplate/" + this.pageParams.type, null, {
          responseType: "blob",
        })
        .then((res) => {
          let blob = new Blob([res.data], {
            type: `application/vnd.openxmlformats-officedocument.spreadsheetml.sheet`,
          });
          const link = document.createElement("a");
          let url = URL.createObjectURL(blob);
          link.href = url;
          link.download = decodeURIComponent(
                  res.headers["content-disposition"].split("=")[1]
          );
          link.click();
          URL.revokeObjectURL(url);
          link.remove();
        })
        .catch((err) => console.warn(err));
    },
    // 导入
    customImport(e) {
      let file = e.file;
      let formData = new FormData();
      formData.append("file", file);
      this.$http
        .post(
          "/inspection/record/importExcel/" + this.pageParams.type,
          formData
        )
        .then((res) => {
          if (res.success) {
            this.pageGet(true);
            this.$message.success("导入成功！");
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          this.$message.error("导入失败！");
        });
    },
    // 删除列表项目
    handlePageDelete(row) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      }).then(() => {
        let params = { id: row.id };
        this.$http
          .post("/inspection/record/delete", null, { params })
          .then((res) => {
            if (res.success) {
              this.$message.success(res.data);
              this.pageGet(true);
            } else {
              this.$message.erroe(res.msg);
            }
          })
          .catch((err) => {
            this.$message.erroe("操作失败！" + err);
          });
      });
    },
    // 详情
    gotoDetail(data) {
      this.$router.push(
        "/single/inspection/records/detail/" +
          this.pageParams.type +
          "/" +
          data.id
      );
    },
    goto(data) {
      this.handleData = Object.assign({}, this.defaultHandleData);
      this.dialogVisible = true;
      this.$refs.form && this.$refs.form.clearValidate();
      if (data) {
        this.handleData = Object.assign({}, data);
        this.title = "编辑";
      } else {
        this.title = "新增";
      }
    },
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.handleData.id) {
            this.handleUpdate();
          } else {
            this.handleData.type = this.pageParams.type;
            this.handleInsert();
          }
        }
      });
    },
    handleAfterFn() {
      this.dialogVisible = false;
      this.pageGet(true);
    },
    resetFields() {
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang="less" scoped>
.report-main {
  width: 100%;
  height: 100%;
  background-color: #fff;
  .nav-list {
    width: 100%;
    height: 40px;
    line-height: 40px;
    display: flex;
  }
  .nav-con {
    width: 160px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    border-left: 1px solid #1a67d0;
    border-right: 1px solid #1a67d0;
    border-top: 1px solid #1a67d0;
    color: #1a67d0;
    font-weight: bold;
  }
  .nav-con:hover {
    cursor: pointer;
  }
  .nav-con i {
    color: #1a67d0;
    margin-right: 5px;
    font-weight: bold;
    font-size: 20px;
  }
  .nav-con-active {
    color: #fff;
    background: #1a67d0;
    border-bottom: 1px solid #1a67d0;
  }
  .nav-con-active i {
    color: #fff;
  }

  .tit-search {
    padding: 20px 20px 0;
  }
  .table-wrapper {
    padding: 0 20px 20px;
    background: #fff;
    height: calc(100% - 119px);
  }
}
</style>
