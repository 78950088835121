export default function Configs() {
    const searchItems = [
        {
            label: "记录人",
            span: 12,
            model: "recordName",
            clearable: true,
            type: "input",
        },
        {
            label: "记录时间",
            span: 12,
            type: "date",
            model: "recordDate",
            clearable: true,
        },
    ];
    const tableColumns = [
        {
            label: "记录人",
            prop: "recordName",
            align: "center",
            minWidth: 140,
            show: true
        },
        {
            label: "记录时间",
            prop: "recordTime",
            align: "center",
            show: true
        },
        {
            label: "操作",
            name: 'custom',
            align: "center",
            show: true
        },
    ];

    const formItems = [
        {
            label: "记录人",
            span: 12,
            model: "recordName",
            clearable: true,
            placeholder: '请输入',
        },
        {
            label: "记录时间",
            span: 12,
            type: "datetime",
            model: "recordTime",
            clearable: true,
            placeholder: '请选择',
        },
    ];
    const formRules = {
        recordName: [{
            required: true,
            message: "请输入",
            trigger: "blur"
        }],
        recordTime: [{
            required: true,
            message: "请选择",
            trigger: "blur"
        }],
    }
    return {
        searchItems,
        tableColumns,
        formItems,
        formRules
    };
}
